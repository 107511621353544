import * as spa from 'single-spa';
import interop from './interop.js'
import path from 'path';

const runtime = Window.runtime || {};

const disabled = () => {}
runtime._log = {
  log: disabled, trace: disabled, debug: disabled, info: disabled, warn: disabled, error: disabled,
  dir: disabled, dirxml: disabled, table: disabled, group: disabled, groupCollapsed: disabled, groupEnd: disabled,
  count: disabled, countReset: disabled, time: disabled, timeLog: disabled, timeEnd: disabled,
  off(){ Object.keys(this).map(m => globalThis.console[m] && (this[m] = globalThis.console[m], globalThis.console[m]=disabled) )},
  on(){ Object.keys(this).map(m => globalThis.console[m] && (globalThis.console[m]=this[m], this[m]=disabled) )}
}
const spa_log={ error: globalThis.console.error, info: globalThis.console.info }
runtime?._env!=='dev' && !location.hostname.includes('localhost')  && !location.hostname.includes('acc.') && runtime._log.off();

// log unhandled promise rejections to console
// https://developer.mozilla.org/en-US/docs/Web/API/PromiseRejectionEvent
// https://developer.mozilla.org/en-US/docs/Web/API/Window/unhandledrejection_event
window.addEventListener("unhandledrejection", (event) => console.error(`UNHANDLED PROMISE REJECTION: ${event.reason}`, event));

// Some formats are missing this. Might add it to the make index.js instead of the bootstrapper.
// But 'fixes' should be in the bootloader.
window.path = path

const defaultApps = {
  shell: {
    bundle: 'app-full',
    active: () => true,
  }
}

// Central interop
// eslint-ignore-next-line
const parcel = spa.mountRootParcel( interop , { domElement: document.body } );
parcel.mountPromise.then( () => { return parcel.update()})

if (!runtime?.apps) console.log('No apps set up! Using default.')
const preloads = []

for(const [name, config] of Object.entries(runtime?.apps || defaultApps)) {
  const appname = name.startsWith('app-') ? name : `app-${name}`
  const subapp = appname.substring(4)
  //PATCH: mangl start route. should be set by nav/dnx-cli
  let active = `/${subapp.replace('mangl-', '')}`
  if (typeof config.active === 'function') active = config.active;
  if (typeof config.active === 'boolean') active = () => config.active;

  const appConfig = {
    name: appname,
    app: () => System.import( `${config.bundle || appname}` ),
    activeWhen: active
  }
  
  // give subapps a chance to perform vuex module registration hooks prior to app mount
  if (config.providesVuexModule) {
    preloads.push((function preload() { 
      console.log(`[${appname}] Performing preload!`); 
      return appConfig.app(); 
    })())
  }

  spa.registerApplication(appConfig)
}

// HACK: Allow subapps to register themselves so shell can signal being ready to receive them
let _resolveShellReady = undefined;
const _shellReady = new Promise(resolve => _resolveShellReady = resolve);
Window._shellReady = _shellReady;
Window._shellReady.resolve = _resolveShellReady;

// Shared errorhandler. This should go to generic logging!
spa.addErrorHandler( (err) => {
  const { name, appOrParcelName, message, stack } = err

  // It failed to load, so delete from registry to retry
  switch(spa.getAppStatus(appOrParcelName)) {
    case spa.LOAD_ERROR: spa_log.error(`%c[${appOrParcelName}]%c ${stack}`, 'font-weight:700;color:black', 'font-weight:inherit;color:auto'); break;//System.delete unknown System.delete(System.resolve(appOrParcelName)); break;
    case spa.LOADING_SOURCE_CODE: spa_log.error(`%c[${appOrParcelName}]%c ${stack}`, 'font-weight:700;color:black', 'font-weight:inherit;color:auto'); break;
    case spa.SKIP_BECAUSE_BROKEN: spa_log.info(`%c[${appOrParcelName}]%c ${name}:${message}\n${stack}`, 'font-weight:700;color:black', 'font-weight:inherit;color:auto');  break;
    default: console.info(`%c[${appOrParcelName}]%c ${name}:${message}\n${stack}`, 'font-weight:700;color:black', 'font-weight:inherit;color:auto')
  }
})

console.time('[PRELOAD]');
// wait for all preloads to finish, be it success or failure
Promise.allSettled(preloads).then(() => { 
  console.timeEnd('[PRELOAD]'); 
  spa.start({urlRerouteOnly:true}); 
})
