const instances = {}
const parcelConfig = {
  bootstrap(props) { return Promise.resolve(); },
  mount(props) { 
    Window.interop = parcelConfig;
    return Promise.resolve(); 
  },

  unmount(props) { return Promise.resolve(); },
  update(props) { 
    return Promise.resolve(); 
  },

  register(bridge) {
    const name = bridge.name || bridge.constructor.name;
    instances[name] = bridge;
  },
  unregister(bridge) {
    const name = bridge.name || bridge.constructor.name;
  },

  dispatch(name, method, ...args) {
    const bridge = instances[name];
    bridge[method]( ...args )
  },
  
  load(name) {
    document?.dispatchEvent( new CustomEvent('dnx:load-app', { detail: { appmodule: name } }))
  }
};

export default parcelConfig